// components/ReviewCard.jsx
import React from 'react';
import './ReviewCard.css';
import redditIcon from '../assets/pngwing.com.png';  // src/assets 폴더에 이미지 저장

function ReviewCard({ review }) {
    const isRedditReview = review.reviewType === 'reddit';
    
    // Reddit 리뷰인 경우 전체 카드를 클릭 가능하게 만들기 위한 핸들러
    const handleCardClick = () => {
        if (isRedditReview && review.url) {
            window.open(review.url, '_blank', 'noopener,noreferrer');
        }
    };
    
    return (
        <div 
            className={`review-card ${isRedditReview ? 'reddit-review clickable' : ''}`}
            onClick={handleCardClick}
            data-type={review.reviewType}
        >
            <div className="review-header">
                <div className="user-info">
                    <div className="user-name">
                        {isRedditReview ? review.title : review.reviewer}
                    </div>
                    <div className="review-date">
                        {new Date(review.time || review.created_utc).toLocaleDateString()}
                    </div>
                </div>
                <div className="rating">
                    {!isRedditReview && (
                        <>
                            {'★'.repeat(review.rating)}
                            {'☆'.repeat(5 - review.rating)}
                        </>
                    )}
                    {isRedditReview && (
                        <span className="reddit-score">
                            <img 
                                src={redditIcon}
                                alt="Reddit" 
                                className="reddit-icon"
                            />
                            Score: {review.score}
                        </span>
                    )}
                </div>
            </div>
            <div className="review-content">
                {review.text}
            </div>
            <div className="review-footer">
                <span className="review-source">{review.source}</span>
                {isRedditReview && (
                    <span className="reddit-link-hint">Click to view on Reddit →</span>
                )}
            </div>
        </div>
    );
}

export default ReviewCard;