// components/ReviewPanel.jsx
import React from 'react';
import ReviewCard from './ReviewCard';
import './ReviewPanel.css';

function ReviewPanel({ apartment, reviews }) {
    console.log('13. ReviewPanel rendered with:', { apartment, reviews });
    
    return (
        <div className="review-panel-container">
            <div className="review-panel-header">
                <h2>Reviews</h2>
                {apartment && (
                    <div className="apartment-name">{apartment.name}</div>
                )}
            </div>
            <div className="reviews-list">
                {reviews && reviews.length > 0 ? (
                    reviews.map((review, index) => {
                        console.log('14. Rendering review:', review);
                        return <ReviewCard key={index} review={review} />;
                    })
                ) : (
                    <div className="no-reviews">
                        {apartment ? "No reviews yet" : "Select an apartment to see reviews"}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ReviewPanel;