// frontend/src/services/api.js

export const api = {
    getApartments: async () => {
        try {
            const response = await fetch('/data/apartment_info.json');
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching apartments:', error);
            return { apartments: [] };
        }
    },

    getApartmentReviews: async (apartmentName) => {
        try {
            const response = await fetch('/data/reem_island_reviews.json');
            const data = await response.json();
            
            // Google 리뷰 필터링
            const googleReviews = data.google_reviews.filter(review => 
                review.apartment.toLowerCase().includes(apartmentName.toLowerCase())
            ).map(review => ({
                ...review,
                reviewType: 'google'
            }));

            // Reddit 리뷰 필터링
            const redditReviews = data.reddit_reviews.filter(review =>
                review.apartment.toLowerCase().includes(apartmentName.toLowerCase())
            ).map(review => ({
                reviewer: review.title,
                rating: review.score,
                text: review.text,
                time: review.created_utc,
                source: 'Reddit',
                url: review.url,         // URL 추가
                title: review.title,     // 제목 추가
                score: review.score,     // Reddit 점수
                reviewType: 'reddit'
            }));

            console.log('Filtered Reddit reviews:', redditReviews); // 디버깅용

            const allReviews = [...googleReviews, ...redditReviews];
            return allReviews;
        } catch (error) {
            console.error('Error fetching reviews:', error);
            return [];
        }
    }
};