// App.js
import React, { useState, useEffect } from 'react';
import useMediaQuery from './hooks/useMediaQuery';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import SearchBar from './components/SearchBar';
import ReviewPanel from './components/ReviewPanel.jsx';
import ApartmentInfo from './components/ApartmentInfo';
import { api } from './services/api';
import 'leaflet/dist/leaflet.css';
import './App.css';
import TopBar from './components/TopBar';
import ReviewCard from './components/ReviewCard';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// 기본 마커 아이콘 설정
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

function App() {
    const [apartments, setApartments] = useState([]);
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [filteredBuildings, setFilteredBuildings] = useState([]);
    const [view, setView] = useState('map');
    const [showApartmentInfo, setShowApartmentInfo] = useState(false);
    const [showReviews, setShowReviews] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    

    useEffect(() => {
        const loadApartments = async () => {
            const data = await api.getApartments();
            setApartments(data.apartments);
            setFilteredBuildings(data.apartments);
        };
        loadApartments();
    }, []);

    useEffect(() => {
        if (selectedApartment) {
            const loadReviews = async () => {
                console.log('1. Loading reviews for apartment:', selectedApartment);
                const reviewData = await api.getApartmentReviews(selectedApartment.name);
                console.log('2. Review data received:', reviewData);
                if (Array.isArray(reviewData)) {
                    console.log('3. Setting reviews state with:', reviewData.length, 'reviews');
                    setReviews(reviewData);
                } else {
                    console.error('Review data is not an array:', reviewData);
                }
            };
            loadReviews();
        }
    }, [selectedApartment]);

    const handleSearch = (value) => {
        setSearchTerm(value);
        if (!value.trim()) {
            setFilteredBuildings(apartments);
        } else {
            const filtered = apartments.filter(building => 
                building.name.toLowerCase().includes(value.toLowerCase()) ||
                building.area.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredBuildings(filtered);
        }
    };

    const resetSearch = () => {
        setSearchTerm('');
        setFilteredBuildings(apartments);
    };

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const isMobile = useMediaQuery('(max-width: 767px)');

    const getLayoutStyle = () => {
        if (isDesktop) {
            return {
                display: 'flex',
                gap: '20px'
            };
        }
        if (isTablet) {
            return {
                display: 'flex',
                flexDirection: 'column',
                gap: '15px'
            };
        }
        return {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        };
    };

    const handleMarkerClick = (apt) => {
        console.log('Marker clicked:', apt);
        setSelectedApartment(apt);
        setShowApartmentInfo(true);
    };

    const handleCloseInfo = () => {
        setShowApartmentInfo(false);
        setSelectedApartment(null);
        setReviews(null);
    };

    return (
        <div className="app-container">
            <TopBar view={view} setView={setView} />
            <div className="divider-line"></div>
            <div className={`main-content ${isMobile ? 'mobile' : ''}`}>
                {isMobile ? (
                    // 모바일 레이아웃
                    <>
                        <div className="map-section mobile">
                            <div className="map-search">
                                <input
                                    type="text"
                                    placeholder="Search apartments..."
                                    value={searchTerm}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    className="map-search-input"
                                />
                                {searchTerm && (
                                    <button 
                                        className="map-search-reset" 
                                        onClick={resetSearch}
                                    >
                                        ×
                                    </button>
                                )}
                            </div>
                            <MapContainer
                                center={[24.4973, 54.3877]}
                                zoom={15}
                                style={{ height: '100%', width: '100%' }}
                                className="map-dark"
                                zoomControl={!isMobile}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                {filteredBuildings.map((apt, index) => (
                                    <Marker
                                        key={`${apt.name}-${index}`}
                                        position={apt.coords}
                                        icon={DefaultIcon}
                                        eventHandlers={{
                                            click: () => {
                                                console.log('Marker clicked:', apt);
                                                handleMarkerClick(apt);
                                            }
                                        }}
                                    >
                                        <Popup>{apt.name}</Popup>
                                    </Marker>
                                ))}
                            </MapContainer>
                        </div>

                        {showApartmentInfo && (
                            <div className="info-panel mobile">
                                <button 
                                    className="close-btn"
                                    onClick={handleCloseInfo}
                                >
                                    ×
                                </button>
                                <ApartmentInfo apartment={selectedApartment} />
                            </div>
                        )}

                        <div className="review-panel mobile">
                            <ReviewPanel 
                                apartment={selectedApartment} 
                                reviews={reviews}
                            />
                        </div>
                    </>
                ) : (
                    // 데스크톱/태블릿 레이아웃 (기존 코드)
                    <>
                        <div className="review-panel">
                            <ReviewPanel 
                                apartment={selectedApartment} 
                                reviews={reviews}
                            />
                        </div>
                        
                        <div className={`map-section ${!showApartmentInfo ? 'expanded' : ''}`}>
                            <div className="map-search">
                                <input
                                    type="text"
                                    placeholder="Search apartments..."
                                    value={searchTerm}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    className="map-search-input"
                                />
                                {searchTerm && (
                                    <button 
                                        className="map-search-reset" 
                                        onClick={resetSearch}
                                    >
                                        ×
                                    </button>
                                )}
                            </div>
                            <MapContainer
                                center={[24.4973, 54.3877]}
                                zoom={15}
                                style={{ height: '100%', width: '100%' }}
                                className="map-dark"
                                zoomControl={!isMobile}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                {filteredBuildings.map((apt, index) => (
                                    <Marker
                                        key={`${apt.name}-${index}`}
                                        position={apt.coords}
                                        icon={DefaultIcon}
                                        eventHandlers={{
                                            click: () => {
                                                console.log('Marker clicked:', apt);
                                                handleMarkerClick(apt);
                                            }
                                        }}
                                    >
                                        <Popup>{apt.name}</Popup>
                                    </Marker>
                                ))}
                            </MapContainer>
                        </div>

                        {showApartmentInfo && (
                            <div className="info-panel resizable">
                                <button 
                                    className="close-btn"
                                    onClick={handleCloseInfo}
                                >
                                    ×
                                </button>
                                <ApartmentInfo apartment={selectedApartment} />
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="divider-line"></div>
            <div className="bottom-bar">
                <div className="bottom-bar-content">
                    <span>© 2024 REVIEW.AE All rights reserved.</span>
                </div>
            </div>
        </div>
    );
}

export default App;