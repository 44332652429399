// components/TopBar.jsx
import React, { useState } from 'react';
import './TopBar.css';

const languages = [
  { code: 'en', name: 'English' },
  { code: 'ru', name: 'Русский' },
  { code: 'ar', name: 'العربية' },
  { code: 'zh', name: '中文' },
  { code: 'ko', name: '한국어' },
  { code: 'ja', name: '日本語' }
];

function TopBar({ view, setView }) {
  const [showLanguages, setShowLanguages] = useState(false);

  const handleLogoClick = () => {
    window.location.reload();
  };

  return (
    <div className="topbar">
      <div className="topbar-logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        REVIEW.AE
      </div>

      {/* <div className="view-toggle">
        <button 
          className={`toggle-btn ${view === 'map' ? 'active' : ''}`}
          onClick={() => setView('map')}
        >
          Map
        </button>
        <button 
          className={`toggle-btn ${view === 'list' ? 'active' : ''}`}
          onClick={() => setView('list')}
        >
          List
        </button>
      </div> */}

      {/* <div className="language-selector">
        <button 
          className="language-btn"
          onClick={() => setShowLanguages(!showLanguages)}
        >
          Language
        </button>
        {showLanguages && (
          <div className="language-dropdown">
            {languages.map((lang) => (
              <button 
                key={lang.code}
                className="language-option"
                onClick={() => {
                  // 언어 변경 로직
                  setShowLanguages(false);
                }}
              >
                {lang.name}
              </button>
            ))}
          </div>
        )}
      </div> */}
    </div>
  );
}

export default TopBar;