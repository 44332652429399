// src/components/ApartmentInfo.js
import React from 'react';

function ApartmentInfo({ apartment }) {
    if (!apartment) {
        return (
            <div className="apartment-info">
                <p>Select an apartment to see details</p>
            </div>
        );
    }

    return (
        <div className="apartment-info">
            <h2>{apartment.name}</h2>
            <div className="info-section">
                <h3>Details</h3>
                <p><strong>Developer:</strong> {apartment.developer}</p>
                <p><strong>Completion Date:</strong> {apartment.completion_year}</p>
                <p><strong>Type:</strong> {apartment.type}</p>
                <p><strong>Total Floors:</strong> {apartment.floors}</p>
                <p><strong>Total Units:</strong> {apartment.units}</p>
            </div>

            {apartment.units_info && (
                <div className="info-section">
                    <h3>Unit Information</h3>
                    {Object.entries(apartment.units_info).map(([type, info]) => (
                        <div key={type} className="unit-type">
                            <h4>{type.replace('_', ' ').toUpperCase()}</h4>
                            <p><strong>Size:</strong> {info.size}</p>
                            <p><strong>Yearly Rent:</strong> {info.rent.yearly}</p>
                            <p><strong>Monthly Rent:</strong> {info.rent.monthly}</p>
                            <p><strong>Sale Price:</strong> {info.sale}</p>
                        </div>
                    ))}
                </div>
            )}

            {apartment.maintenance_fees && (
                <div className="info-section">
                    <h3>Maintenance</h3>
                    <p><strong>Yearly Fee (per sqft):</strong> {apartment.maintenance_fees.yearly_per_sqft}</p>
                    <p><strong>Includes:</strong></p>
                    <ul>
                        {apartment.maintenance_fees.includes.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            )}

            {apartment.amenities && (
                <div className="info-section">
                    <h3>Amenities</h3>
                    <ul>
                        {apartment.amenities.map((amenity, index) => (
                            <li key={index}>{amenity}</li>
                        ))}
                    </ul>
                </div>
            )}

            {apartment.nearby && (
                <div className="info-section">
                    <h3>Nearby Places</h3>
                    <ul>
                        {apartment.nearby.map((place, index) => (
                            <li key={index}>{place}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default ApartmentInfo;